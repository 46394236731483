import { useContext, useState } from "react";
import Modal from "../../UI/Modal";
import SearchBar from "../../UI/SearchBar";
import module from "./style.module.css";
import logo from "../../assets/Logo.png";
import usdt from "../../assets/USDT.png";
import UserPorgressContext from "../../context/UserProgressContext";
import SendMoneyProgressContext from "../../context/SendMoneyProgressContext";
import { Link } from "react-router-dom";
import close from "../../assets/closeButton.png";

function SendMoney(props) {
  const [enteredValue, setEnteredValue] = useState("");
  const userProgressCtx = useContext(UserPorgressContext);
  const sendMoneyCtx = useContext(SendMoneyProgressContext);

  function searchChangeHandler(value) {
    setEnteredValue(value);
  }

  

  return (
    <div>
      <Modal
        onClose={props.onClose}
      >
        <div className={module["closeButton"]}>
          <button className={module["buttonClose"]}>
            <img src={close} alt="close" />
          </button>
        </div>
        <div className={module["modalHeading"]}>
          <h2>Select Currency</h2>
        </div>
        <div>
          <SearchBar onChange={searchChangeHandler} />
        </div>
        <div>
          <div className={module["optionParent"]}>
            <div className={module["buttonContent"]}>
              <span>
                <img
                  src={logo}
                  alt="BitCoin"
                  className={module["imageClass"]}
                />
              </span>
              <span className={module["textClass1"]}>SKT</span>
            </div>
            <input type="radio" className={module["inputStyle"]} />
          </div>

          <div className={module["optionParent"]}>
            <div className={module["buttonContent"]}>
              <span>
                <img src={usdt} alt="USDT" className={module["imageClass"]} />
              </span>
              <span className={module["textClass1"]}>USDT</span>
            </div>
            <input type="radio" className={module["inputStyle"]} />
          </div>

          <div className={module["optionParent"]}>
            <div className={module["buttonContent"]}>
              <span>
                <img
                  src={logo}
                  alt="BitCoin"
                  className={module["imageClass"]}
                />
              </span>
              <span className={module["textClass1"]}>ETH</span>
            </div>
            <input type="radio" className={module["inputStyle"]} />
          </div>
        </div>
        <div className={module["buttonClass"]}>
          <button>Continue</button>
        </div>
      </Modal>
{/*
      <Modal
        open={
          userProgressCtx.progress === "sendMoney" &&
          sendMoneyCtx.progress === "enterAmount"
        }

      >
        <div className={module["closeButton"]}>
          <button className={module["buttonClose"]}>
            <img src={close} alt="close" />
          </button>
        </div>
        <div className={module["parentContainer"]}>
          <div className={module["modalHeading"]}>
            <h2>Send Money</h2>
          </div>
          <div className={module["textClass2"]}>
            <span>
              Please enter user information that you want to send money and
              enter an amount
            </span>
          </div>
          <div>
            <span>Recent Contact</span>
            <ul>
              <li>List of Contacts</li>
            </ul>
          </div>
          <div className={module["spanTextWallet"]}>
            <span>Wallet Address</span>
          </div>
          <div>
            <input
              type="text"
              value="abc"
              readOnly
              className={module["inputWalletAdd"]}
            />
          </div>
          <div className={module["spanText2"]}>
            <span>Choose Account</span>
          </div>
          <div className={module["parentContainerAccountValue"]}>
            <div>
              <select className={module["dropDownStyleAccount"]}>
                <option value="mainAccount">Main Account</option>
                <option value="secondAccount">Second Account</option>
              </select>
            </div>
            <div>
              <input type="text" className={module["inputAmount"]} />
              <input type="text" value="SKD" readOnly />
            </div>
          </div>

          <div>
            <button className={module["continueButton2"]}>Continue</button>
          </div>
        </div>
      </Modal>

      <Modal
        open={
          userProgressCtx.progress === "sendMoney" &&
          sendMoneyCtx.progress === "reviewTranscation"
        }

        // open={true}
      >
        <div className={module["closeButton"]}>
          <button className={module["buttonClose"]}>
            <img src={close} alt="close" />
          </button>
        </div>
        <div className={module["parentContainer"]}>
          <div className={module["modalHeading"]}>
            <h2>Review details of your transfer</h2>
          </div>
          <div className={module["textClass2"]}>
            <span>
              Please enter user information that you want to send money and
              enter an amount
            </span>
          </div>
          <div className={module["amountText"]}>
            <span>Amount to be sent</span>
          </div>
          <div className={module["amountValue"]}>
            <h2 className={module["h2Span"]}>21.00</h2>
            <h5 className={module["h5Span"]}>SKD</h5>
          </div>
          <div className={module["editGasText"]}>
            <div>
              <span className={module["gasText"]}>Gas Fee</span>
            </div>
            <div>
              <Link> Edit</Link>
            </div>
          </div>
          <div className={module["gasFeeParent"]}>
            <div className={module["estimateText"]}>
              <span>Estimated GAS fees</span>
            </div>
            <div className={module["estimateAmountText"]}>
              <span>$5.95</span>
            </div>
          </div>

          <div className={module["gasFeeParent"]}>
            <div className={module["estimateText"]}>
              <span>Max fees</span>
            </div>
            <div className={module["estimateAmountText"]}>
              <span>0.00030012332 SKd</span>
            </div>
          </div>

          <div className={module["editGasText"]}>
            <div>
              <span className={module["gasText"]}>Total</span>
            </div>
            <div>
              <Link> Edit</Link>
            </div>
          </div>

          <div className={module["gasFeeParent"]}>
            <div className={module["estimateText"]}>
              <span>Total Amount + Gas Fees</span>
            </div>
            <div className={module["estimateAmountText"]}>
              <span>$25.95</span>
            </div>
          </div>

          <div className={module["gasFeeParent"]}>
            <div className={module["estimateText"]}>
              <span>Max Amount</span>
            </div>
            <div className={module["estimateAmountText"]}>
              <span>$31.95</span>
            </div>
          </div>

          <div>
            <button className={module["continueButton2"]}>Send Money</button>
          </div>
        </div>
      </Modal>

      <Modal
        open={
          userProgressCtx.progress === "sendMoney" &&
          sendMoneyCtx.progress === "viewStatus"
        }
        
        // open={true}
      >
        <div className={module["closeButton"]}>
          <button className={module["buttonClose"]}>
            <img src={close} alt="close" />
          </button>
        </div>
        <div className={module["transactionDetailsHeader"]}>
          <h2>Transcation Details</h2>
        </div>

        <div className={module["transcationDetailsText"]}>
          <span>Here are your transcations details</span>
        </div>

        <div className={module["amountText"]}>
          <span>Amount to be sent</span>
        </div>

        <div className={module["amountValue"]}>
          <h2 className={module["h2Span"]}>21.00</h2>
          <h5 className={module["h5Span"]}>SKD</h5>
        </div>

        <div className={module["detailsText"]}>
          <span>Transaction details</span>
        </div>

        <div className={module["statusContainer"]}>
          <div className={module["estimateText"]}>
            <span>Status</span>
          </div>
          <div className={module["estimateAmountText"]}>
            <span>Pending/Success</span>
          </div>
        </div>

        <div className={module["toContainer"]}>
          <div className={module["estimateText"]}>
            <span>To</span>
          </div>
          <div className={module["estimateAmountText"]}>
            <span>Name of User</span>
          </div>
        </div>

        <div className={module["gasFeeParent"]}>
          <div className={module["estimateText"]}>
            <span>Total Amount + Gas Fees</span>
          </div>
          <div className={module["estimateAmountText"]}>
            <span>$25.95</span>
          </div>
        </div>

        <div className={module["gasFeeParent"]}>
          <div className={module["estimateText"]}>
            <span>Max Amount</span>
          </div>
          <div className={module["estimateAmountText"]}>
            <span>$31.95</span>
          </div>
        </div>

        <div className={module["gasFeeParent"]}>
          <div className={module["estimateText"]}>
            <span>Max fees</span>
          </div>
          <div className={module["estimateAmountText"]}>
            <span>0.00030012332 SKd</span>
          </div>
        </div>

        <div className={module["gasFeeParent"]}>
          <div className={module["estimateText"]}>
            <span>Estimated GAS fees</span>
          </div>
          <div className={module["estimateAmountText"]}>
            <span>$5.95</span>
          </div>
        </div>
      </Modal> */}
    </div>
  );
}

export default SendMoney;
