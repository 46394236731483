import { createContext, useState } from "react";

const SendMoneyProgressContext = createContext({
  progress: "",
  showSelectCurrency: () => {},
  hideSelectCurrency: () => {},
  showSelectContact: () => {},
  hideSelectContact: () => {},
  showReview: () => {},
  hideReview: () => {},
  showStatus: () => {},
  hideStatus: () => {},
});

export function SendMoneyProgressContextProvider({ children }) {
  const [userProgress, setUserProgress] = useState("");

  function showSelectCurrency() {
    setUserProgress("selectCurrency");
  }

  function hideSelectCurrency() {
    setUserProgress("");
  }

  function showSelectContact() {
    setUserProgress("enterAmount");
  }

  function hideSelectContact() {
    setUserProgress("");
  }

  function showReview() {
    setUserProgress("reviewTranscation");
  }

  function hideReview() {
    setUserProgress("");
  }

  function showStatus() {
    setUserProgress("viewStatus");
  }

  function hideStatus() {
    setUserProgress("");
  }

  const sendMoneyProgressCtx = {
    progress: userProgress,
    showSelectCurrency,
    hideSelectCurrency,
    showSelectContact,
    hideSelectContact,
    showReview,
    hideReview,
    showStatus,
    hideStatus,
  };

  return (
    <SendMoneyProgressContext.Provider value={sendMoneyProgressCtx}>
      {children}
    </SendMoneyProgressContext.Provider>
  );
}
export default SendMoneyProgressContext;
