import module from "./profileStyle.css";
import grouptr from "../../assets/GroupToRight.png";
import logo from "../../assets/Logo.png";
import vectorbl from "../../assets/VectorBottomLeft.png";
import middleImg from "../../assets/MiddleImg.png";
import useInput from "../../hooks/use-input";
import { useNavigate } from "react-router-dom";

function Profile() {

  const navigate = useNavigate();
  const {
    value: addressLine1,
    valueChangeHandler: addL1ChangeHandler,
    reset: addL1Reset,
  } = useInput();

  const {
    value: addressLine2Value,
    valueChangeHandler: addL2ChangeHandler,
    reset: addL2Reset,
  } = useInput();

  const {
    value: cityValue,
    valueChangeHandler: cityChangeHandler,
    reset: cityReset,
  } = useInput();

  const {
    value: stateValue,
    valueChangeHandler: stateChangeHandler,
    reset: stateReset,
  } = useInput();

  const {
    value: countryValue,
    valueChangeHandler: countryChangeHandler,
    reset: countryReset,
  } = useInput();

  const {
    value: pcValue,
    valueChangeHandler: pcChangeHandler,
    reset: pcReset,
  } = useInput();

  const {
    value: dobValue,
    valueChangeHandler: dobChangeHandler,
    reset: dobReset,
  } = useInput();

  const {
    value: fbValue,
    valueChangeHandler: fbChangeHandler,
    reset: fbReset,
  } = useInput();

  const {
    value: xValue,
    valueChangeHandler: xChangeHandler,
    reset: xReset,
  } = useInput();

  const {
    value: linkedInValue,
    valueChangeHandler: linkedInChangeHandler,
    reset: linkedInReset,
  } = useInput();

  function onSubmitHandler(event) {
    event.preventDefault();
    navigate("/dashboard");
    addL1Reset();
    addL2Reset();
    cityReset();
    stateReset();
    countryReset();
    pcReset();
    dobReset();
    fbReset();
    xReset();
    linkedInReset();
  }

  return (
    <div className={module["parentContainer"]}>
      <div className={module["container"]}>
        <div className={module["innerContainer"]}>
          <div className={module["firstParent"]}>
            <div className={module["innerFirstParent"]}>
              <div className={module["subInnerParent"]}>
                <div className={module["logo"]}>
                  <img src={logo} alt="SkenderPayLogo" />
                </div>
                <div className={module["textSpan"]}>Skenderpay</div>
              </div>
              <div className={module["firstImage"]}>
                <img src={grouptr} alt="Vector Top Right" />
              </div>
            </div>
            <div className={module["middleImage"]}>
              <img src={middleImg} alt="Middle showcase" />
            </div>

            <div className={module["textParent"]}>
              <span className={module["text1"]}>
                {" "}
                Speady, Easy and Fast <br />{" "}
              </span>
              <span className={module["text2"]}>
                Overpay help you set saving goals, earn cash back offers, Go to
                disclaimer <br />
                for more details and get paychecks up to two days early. Get a
                <span className={module["dollarText"]}>$20</span>
                bonus <br />
                when you receive qualifying direct deposits
              </span>
            </div>
            <div className={module["secondImage"]}>
              <img src={vectorbl} alt="Vector Bottom Left" />
            </div>
          </div>
          <div className={module["secondParent"]}>
            <div className={module["formParent"]}>
              <div className={module["formHeading"]}>
                <h2>Complete Your Profile</h2>
              </div>
              <form onSubmit={onSubmitHandler}>
                <div>
                  <label
                    className={module["labelAddressLine1"]}
                    htmlFor="addressLine1"
                  >
                    Address(Line 1) *
                  </label>
                  <input
                    type="text"
                    id="addressLine1"
                    className={module["inputaddressLine1"]}
                    required
                    onChange={addL1ChangeHandler}
                    value={addressLine1}
                  />
                </div>
                <div>
                  <label
                    className={module["labelAddressLine2"]}
                    htmlFor="addressLine2"
                  >
                    Address (Line 2)
                  </label>
                  <input
                    type="text"
                    id="addressLine2"
                    className={module["inputAddressLine2"]}
                    onChange={addL2ChangeHandler}
                    value={addressLine2Value}
                  />
                </div>
                <div>
                  <label className={module["labelCity"]} htmlFor="city">
                    City *
                  </label>
                  <input
                    type="text"
                    id="city"
                    className={module["inputCity"]}
                    onChange={cityChangeHandler}
                    value={cityValue}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="state" className={module["labelState"]}>
                    State *
                  </label>
                  <input
                    id="state"
                    type="text"
                    className={module["inputState"]}
                    onChange={stateChangeHandler}
                    value={stateValue}
                    required
                  />
                </div>

                <div>
                  <label
                    htmlFor="postalCode"
                    className={module["labelPostalCode"]}
                  >
                    Postal Code *
                  </label>
                  <input
                    id="postalCode"
                    type="text"
                    className={module["inputPostalCode"]}
                    onChange={pcChangeHandler}
                    value={pcValue}
                    required
                  />
                </div>

                <div>
                  <label htmlFor="country" className={module["labelCountry"]}>
                    Country *
                  </label>
                  <input
                    id="country"
                    type="text"
                    className={module["inputCountry"]}
                    onChange={countryChangeHandler}
                    value={countryValue}
                    required
                  />
                </div>

                <div>
                  <label htmlFor="dob" className={module["labelDOB"]}>
                    Date of Birth
                  </label>
                  <input
                    id="dob"
                    type="date"
                    className={module["inputDOB"]}
                    onChange={dobChangeHandler}
                    value={dobValue}
                  />
                </div>

                <div>
                  <label htmlFor="fb" className={module["labelFB"]}>
                    FaceBook Profile Link
                  </label>
                  <input
                    id="fb"
                    type="text"
                    className={module["inputFB"]}
                    onChange={fbChangeHandler}
                    value={fbValue}
                  />
                </div>

                <div>
                  <label htmlFor="x" className={module["labelX"]}>
                    X Profile Link
                  </label>
                  <input
                    id="x"
                    type="text"
                    className={module["inputX"]}
                    onChange={xChangeHandler}
                    value={xValue}
                  />
                </div>

                <div>
                  <label htmlFor="linkedIn" className={module["labelLinkedIn"]}>
                    LinkedIn Profile Link
                  </label>
                  <input
                    id="linkedIn"
                    type="text"
                    className={module["inputLinkedIn"]}
                    onChange={linkedInChangeHandler}
                    value={linkedInValue}
                  />
                </div>

                <div>
                  <button type="submit" className={module["loginButton"]}>
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
