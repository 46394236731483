import UserProgressContext from "../../context/UserProgressContext";
import { useContext } from "react";
import Modal from "../../UI/Modal";
import module from "./style.module.css";
import close from "../../assets/closeButton.png";
import { Link } from "react-router-dom";
function TopUp(props) {
  const userProgressCtx = useContext(UserProgressContext);
  return (
    <div>
      <Modal onClose={props.onClose}>                                                                          
        <div className={module["modalParent"]}>
          <div className={module["closeButton"]}>
            <button className={module["buttonClose"]}>
              <img src={close} alt="close" />
            </button>
          </div>
          <div className="modalHeading">
            <h2>Top-up Money</h2>
          </div>
          <div className={module["text1"]}>
            <span>
              Please enter user information that you want to send money <br />
              <span>and enter an amount</span>
            </span>
          </div>
          <div className={module["walletDiv"]}>
            <label
              htmlFor="walletAddress"
              className={module["walletAddressLabel"]}
            >
              Wallet Address
            </label>
            <input
              id="walletAddress"
              type="text"
              readOnly
              value="abc"
              className={module["inputWalletAddress"]}
            />
          </div>
          <div className={module["chooseMethod"]}>
            <div>
              <span>Choose Method</span>
            </div>
            <div>
              <Link>Add +</Link>
            </div>
          </div>

          <div className={module["chooseMethod"]}>
            <div>
              <select className={module["inputDrop"]}>
                <option value="MasterCard">MasterCard</option>
                <option value="Visa">Visa</option>
              </select>
            </div>
            <div>
              <input
                id="value"
                type="text"
                placeholder="Enter the value"
                className={module["inputDrop"]}
              />
            </div>
          </div>
        </div>
      </Modal>
     {/*
      <Modal open={userProgressCtx === 'topup'}>
        <div className={module["modalParent"]}>
          <div className={module["closeButton"]}>
            <button className={module["buttonClose"]}>
              <img src={close} alt="close" />
            </button>
          </div>
          <div className={module["modalHeading"]}>
            <h2>Review detail of your request</h2>
          </div>
          <div className={module["text1"]}>
            <span>
              Please enter user information that you want to send money <br />
              <span>and enter an amount</span>
            </span>
          </div>

          <div className={module["amountValue"]}>
            <h2 className={module["h2Span"]}>21.00</h2>
            <h5 className={module["h5Span"]}>SKD</h5>
          </div>
          <div className={module["editGasText"]}>
            <div>
              <span className={module["gasText"]}>Gas Fee</span>
            </div>
            <div>
              <Link> Edit</Link>
            </div>
          </div>
          <div className={module["gasFeeParent"]}>
            <div className={module["estimateText"]}>
              <span>Estimated GAS fees</span>
            </div>
            <div className={module["estimateAmountText"]}>
              <span>$5.95</span>
            </div>
          </div>

          <div className={module["gasFeeParent"]}>
            <div className={module["estimateText"]}>
              <span>Max fees</span>
            </div>
            <div className={module["estimateAmountText"]}>
              <span>0.00030012332 SKd</span>
            </div>
          </div>

          <div className={module["editGasText"]}>
            <div>
              <span className={module["gasText"]}>Total</span>
            </div>
            <div>
              <Link> Edit</Link>
            </div>
          </div>

          <div className={module["gasFeeParent"]}>
            <div className={module["estimateText"]}>
              <span>Total Amount + Gas Fees</span>
            </div>
            <div className={module["estimateAmountText"]}>
              <span>$25.95</span>
            </div>
          </div>

          <div className={module["gasFeeParent"]}>
            <div className={module["estimateText"]}>
              <span>Max Amount</span>
            </div>
            <div className={module["estimateAmountText"]}>
              <span>$31.95</span>
            </div>
          </div>

          <div>
            <button className={module["continueButton2"]}>Request</button>
          </div>

        </div>
  </Modal> */}
    </div>
  );
}

export default TopUp;
