import React from "react";
import logo from "../../assets/Logo.png";
import middleImg from "../../assets/MiddleImg.png";
import module from "./style.module.css";

const Passwordreset = () => {

  return (
    <div className={module["parentContainer"]}>
      <div className={module["container"]}>
        <div className={module["innerContainer"]}>
          <div className={module["firstParent"]}>
            <div className={module["innerFirstParent"]}>
              <div className={module["subInnerParent"]}>
                <div className={module["logo"]}>
                  <img src={logo} alt="SkenderPayLogo" />
                </div>
                <div className={module["textSpan"]}>Skenderpay</div>
              </div>
            </div>
            <div className={module["middleImage"]}>
              <img src={middleImg} alt="Middle showcase" />
            </div>

            <div className={module["textParent"]}>
              <span className={module["text1"]}>
                {" "}
                Speady, Easy and Fast <br />{" "}
              </span>
              <span className={module["text2"]}>
                Overpay help you set saving goals, earn cash back offers, Go to
                disclaimer <br />
                for more details and get paychecks up to two days early. Get a
                <span className={module["dollarText"]}>$20</span>
                bonus <br />
                when you receive qualifying direct deposits
              </span>
            </div>
          </div>
          <div className={module["secondParent"]}>
            <div className={module["formParent"]}>
              <div className={module["formHeading"]}>
                <h2>Forgot Password</h2>
              </div>
              <div>
                <label className={module["labelUserName"]} htmlFor="userName">
                  User Name
                </label>
                <input
                  type="text"
                  id="userName"
                  className={module["inputUserName"]}
                />
              </div>
              <div className={module["buttonContainer"]}>
                <button className={module["loginButton"]}>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Passwordreset;
