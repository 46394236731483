import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Dashboard from './components/Dashboard/Dashboard';
import Invoices from './components/Dashboard/Invoices';
import Products from './components/Dashboard/Products';
import Home from './components/Index/Home';
import Signup from './components/Signup/signup';
import Profile from './components/Profile/Profile';
import Messages from './components/Messages/Messages';
import SendMoney from './popUpModal/SendMoney/SendMoney';
import Login from './components/login/login';
import Bank from './components/Bank/Bank';
import ReceiveMoney from './popUpModal/ReceiveMoney/ReceiveMoney';
import TopUp from './popUpModal/TopUp/TopUp';
import Passwordreset from './components/PasswordReset/passwordreset';



function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route
            exact
            path='/'
            element={<Login />}
          />
          <Route
            exact
            path='/signup1'
            element={<Home />}
          />
          <Route
            exact
            path='/signup2'
            element={<Signup />}
          />
          <Route
            exact
            path='/dashboard'
            element={<Dashboard />}
          />
          <Route
            path="*"
            element={<Navigate to="/" />}
          />
          <Route
            path='/profile'
            element={<Profile />}
          />
          <Route
            path='/sendmoney'
            element={<SendMoney />}
          />
          <Route
            path='/bankinfo'
            element={<Bank />}
          />
          <Route
            path='/receiveMoney'
            element={<ReceiveMoney />}
          />
          <Route
            path='/topup'
            element={<TopUp />}
          />
          <Route
            path='/invoices'
            element={<Invoices />}
          />
          <Route
            path='/products'
            element={<Products />}
          />
          <Route
            path='/services'
            element={<Products />}
          />
          <Route
            path='/passwordreset'
            element={<Passwordreset />}
          />
          <Route
            path='/messages'
            element={<Messages />}
          />

        </Routes>
      </Router>
    </div>
  );
}

export default App;
