import React, { useState, useEffect } from 'react';
import axios from 'axios';
import topup from "../../assets/topup.png";
import send from "../../assets/send.png";
import request from "../../assets/request.png";
import history from "../../assets/history.png";
import TopupModal from "../../popUpModal/TopUp/TopUp"
import ReceiveMoneyModal from "../../popUpModal/ReceiveMoney/ReceiveMoney"
import SendMoneyModal from "../../popUpModal/SendMoney/SendMoney"
const Wallet = (props) => {
    const [modalType, setModalType] = useState(null);
    const [totalBalance, setTotalBalance] = useState(0); // Initial balance
/*
    useEffect(() => {
        const fetchBalance = async () => {
            try {
                const resp = await fetch("https://skenderpay.xyz:8443/getWalletAdd",
                {
                    method: "POST",
                    body: JSON.stringify({ userId : 1})
                }
                );
                console.log(resp)
                const newBalance = resp.data.balance; 
                setTotalBalance(newBalance);
            } catch (error) {
                console.error('Error fetching balance:', error);
            }
        };

        fetchBalance();
    }, []); */
    const handleModalOpen = (type) => {
        setModalType(type);
    };

    const handleCloseModal = () => {
        setModalType("");
    };
    return (
    <div className="frame9-home-v2-wallet1">
        <div className="dashboard-totalbalance">
            <div className="frame9-home-v2-frame57">
                <span className="frame9-home-v2-text048 bodylargesemibold">
                    <span>Main account</span>
                </span>
                <div className="frame9-home-v2-frame56">
                    <span className="frame9-home-v2-text050 bodylargemedium">
                        <span>0x1A1zP1....vfNa</span>
                    </span>
                    <div className="frame9-home-v2-copy">
                        <div className="frame9-home-v2-group02">
                        <img
                            src="/vectori194-y07.svg"
                            alt="VectorI194"
                            className="frame9-home-v2-vector07"
                        />
                        <img
                            src="/vectori194-artl.svg"
                            alt="VectorI194"
                            className="frame9-home-v2-vector08"
                        />
                        </div>
                    </div>
                </div>
            </div>
            <div className="frame9-home-v2-frame57-footer"></div>
            <div className="frame9-home-v2-balance">
                <div className="frame9-home-v2-title">
                <span className="frame9-home-v2-text052 bodylargemedium">
                    <span>Total Balance</span>
                </span>
                </div>
                <div className="frame9-home-v2-frame59">
                <span className="frame9-home-v2-text054">
                    <span className="frame9-home-v2-text055">
                    {totalBalance} SKD</span>
                </span>
                <div className="frame9-home-v2-up">
                    <span className="frame9-home-v2-text057 bodylargemedium">
                    <span>{totalBalance} USD</span>
                    </span>
                </div>
                </div>
            </div>
        </div>
        <span className="frame9-home-v2-text059 HeadingH6">
            <span>Wallet</span>
        </span>
        <div className="frame9-home-v2-dotsvertical">
            <div className="frame9-home-v2-group03">
                <img
                src="/vectori194-0btn.svg"
                alt="VectorI194"
                className="frame9-home-v2-vector09"
                />
                <img
                src="/vectori194-hfa5h.svg"
                alt="VectorI194"
                className="frame9-home-v2-vector10"
                />
                <img
                src="/vectori194-0htu.svg"
                alt="VectorI194"
                className="frame9-home-v2-vector11"
                />
            </div>
        </div>
        <div className="frame9-home-v2-menu">
                <button className="frame9-home-v2-item" onClick={() => handleModalOpen('topup')}>
                    <div className="frame9-home-v2-icon2">
                        <img src={topup} alt="SkenderPayLogo" id="topup" className='wallet-icon' />
                    </div>
                    <span className="frame9-home-v2-text061 bodymediumsemibold">
                        <span>Top-up</span>
                    </span>
                </button> 
                <button className="frame9-home-v2-item" onClick={() => handleModalOpen('send')}>
                    <div className="frame9-home-v2-icon2">
                        <img src={send} alt="SkenderPayLogo" id="send" className='wallet-icon' />
                    </div>
                    <span className="frame9-home-v2-text063 bodymediumsemibold">
                        <span>Send</span>
                    </span>
                </button>
                <button className="frame9-home-v2-item" onClick={() => handleModalOpen('request')}>
                    <div className="frame9-home-v2-icon2">
                        <img src={request} alt="SkenderPayLogo" id="request" className='wallet-icon' />
                    </div>
                    <span className="frame9-home-v2-text065 bodymediumsemibold">
                        <span>Request</span>
                    </span>
                </button>
                </div>
            {modalType === 'topup' && <TopupModal onClose={handleCloseModal} />}
            {modalType === 'send' && <SendMoneyModal onClose={handleCloseModal} />}
            {modalType === 'request' && <ReceiveMoneyModal onClose={handleCloseModal} />}
    </div>
    );
};

export default Wallet