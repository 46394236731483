import { createContext, useState } from "react";

const ReceiveMoneyProgressContext = createContext({
    progress: '',
    showSelectCurrency: () => {},
    hideSelectCurrency: () => {},
    showSelectContact: () => {},
    hideSelectContact: () => {},
    showReview: () => {},
    hideReview: () => {},
});

export function ReceiveMoneyProgressContextProvider({ children }) {
    const [userProgress, setUserProgress] = useState("");
  
    function showSelectCurrency() {
      setUserProgress("selectCurrency");
    }
  
    function hideSelectCurrency() {
      setUserProgress("");
    }
  
    function showSelectContact() {
      setUserProgress("enterAmount");
    }
  
    function hideSelectContact() {
      setUserProgress("");
    }
  
    function showReview() {
      setUserProgress("reviewTranscation");
    }
  
    function hideReview() {
      setUserProgress("");
    }
  
    function showStatus() {
      setUserProgress("viewStatus");
    }
  
    function hideStatus() {
      setUserProgress("");
    }
  
    const sendMoneyProgressCtx = {
      progress: userProgress,
      showSelectCurrency,
      hideSelectCurrency,
      showSelectContact,
      hideSelectContact,
      showReview,
      hideReview,
      showStatus,
      hideStatus,
    };
  
    return (
      <ReceiveMoneyProgressContext.Provider value={sendMoneyProgressCtx}>
        {children}
      </ReceiveMoneyProgressContext.Provider>
    );
  }
export default ReceiveMoneyProgressContext;
