import {createSlice} from '@reduxjs/toolkit'



const loginSlice = createSlice({
    name: 'login',
    initialState: {
        isLoggedIn: false,
        userId: null,
        firstName: null
    },
    reducers: {
        userLogin(state, actions){
            const data = actions.payload;
            state.isLoggedIn = true;
            state.userId = data.userId;
            state.firstName = data.firstName;
        },
        userLogout(state){
            state.isLoggedIn = false;
            state.userId = null;
            state.firstName = null;
        }
    }
});




export const loginAction = loginSlice.actions;
export default loginSlice;
