import React, { useState, useEffect } from 'react';
import message from '../../assets/message.png'
import dropdown from '../../assets/dropdown.png'
import search from '../../assets/search.png'
import bell from '../../assets/bell.png'
import reddot from '../../assets/redDot.png'
const user = {
  "userId": "3",
  "username" : "XYZ",
  "addressLine1": "123 Main St",
  "addressLine2": "Apt 456",
  "city": "Example City",
  "state": "CA",
  "postalCode": "12345",
  "country": "USA",
  "phoneNumber": "123-456-7890",
  "dateOfBirth": "1990-01-01",
  "facebookProfile": "facebook.com/example",
  "twitterProfile": "twitter.com/example",
  "instagramProfile": "instagram.com/example",
  "linkedinProfile": "linkedin.com/in/example"
}
const Navbar = ({ moduleName, onNavbarClick }) =>  {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  }; 
  const handleLogout = () => {

  };

    return (
    <div className="frame9-home-v2-navbar-right"> 
      <span className="navbar-heading HeadingH4" onClick={onNavbarClick}>
        <h3>{moduleName}</h3>
      </span>
            <div className="frame9-home-v2-notifications">
              <div className="frame9-home-v2-bell">
                <div className="frame9-home-v2-group">
                  <img  src={bell} alt="VectorI194" className="frame9-home-v2-vector" />
                  {/* <img src={reddot} alt="VectorI194" className="frame9-home-v2-vector01" /> */}
                </div>
              </div>
              <img src={reddot} alt="Ellipse741948" className="frame9-home-v2-ellipse74" />
            </div>
            <div className="frame9-home-v2-search">
              <div className="frame9-home-v2-search1">
                <div className="frame9-home-v2-search2">
                  <img
                    src={search}
                    alt="Ellipse739I194"
                    className="frame9-home-v2-ellipse739"
                  />
                  {/* <img src="/line181i194-ah1m.svg" alt="Line181I194" className="frame9-home-v2-line181" /> */}
                </div>
              </div>
            </div>
              <div className="frame9-home-v2-user">
                <div className="frame9-home-v2-frame5">
                  <span className="frame9-home-v2-text002 bodylargeextrabold">
                    <span>{user.username}</span>
                  </span>
                </div>
                <img
                  src={dropdown}
                  alt="chevrondown1948"
                  className="frame9-home-v2-chevrondown"
                  onClick={toggleDropdown}
                />
                  {isDropdownOpen && (
                    <div className="dropdown-content">
                      <h4>{user.username}</h4>
                      <h6>Business Account</h6>
                      <button>Your Details</button>
                      <button onClick={handleLogout}>Logout</button>
                      <button>Account Settings</button>
                    </div>
                  )}
            </div>
          </div>
    );
};

export default Navbar