import module from './searchbar.module.css';
import {FaSearch} from 'react-icons/fa';

export default function SearchBar({onChange, className = ''}){
    return(
        <div className={module['inputWrapper']  + " " + className}>
            <FaSearch id="searchIcon"/>
            <input type='text' placeholder='Type to search' onChange={(e) => onChange(e.target.value)} className={module["inputClass"]}/>
        </div>
    );
}