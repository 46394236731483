import React, { useState } from 'react';
import './Dashboard.css'
import dropdownside from '../../assets/sideway.png'
import date from '../../assets/date.png'
import Wallet from './Wallet'
import Bank from './Bank'
import Navbar from './Navbar'
import NavbarTop from './NavbarTop'
import { useTable, useSortBy } from 'react-table';
const Dashboard = (props) => {
  
const transactions = [
  {
    productName: 'Product 1',
    period: '3 Months',
    type: 'Promotion',
    price: '$835.00',
    status: 'Success'
  },
  {
    productName: 'Product 2',
    period: '6 Months',
    type: 'Full Service',
    price: '$835.00',
    status: 'Success'
  },
  {
    productName: 'Product 3',
    period: '15 Days',
    type: 'Promotion',
    price: '$150.00',
    status: 'Pending'
  },
  {
    productName: 'Product 4',
    period: 'Lifetime',
    type: 'Subscription',
    price: '$200.00',
    status: 'Success'
  }
];

const Table = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns,
      data
    },
    useSortBy
  );

  return (
    <table {...getTableProps()} className="product-list">
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()} className="product-item">
            {headerGroup.headers.map(column => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className={
                  column.isSorted
                    ? column.isSortedDesc
                      ? 'sort-desc'
                      : 'sort-asc'
                    : ''
                }
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className="product-item">
              {row.cells.map(cell => {
                // Add specific classes based on status
                let statusClass = '';
                if (cell.column.id === 'status') {
                  statusClass = cell.value === 'Pending' ? 'product-pending' : 'product-success';
                }
                return (
                  <td {...cell.getCellProps()} className={`product-name ${statusClass}`}>
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const columns = React.useMemo(
  () => [
    {
      Header: 'Product Name',
      accessor: 'productName'
    },
    {
      Header: 'Period',
      accessor: 'period'
    },
    {
      Header: 'Price',
      accessor: 'price'
    },
    {
      Header: 'Status',
      accessor: 'status'
    }
  ],
  []
);

const [isSidebarOpen, setSidebarOpen] = useState(false);

const toggleSidebar = () => {
  setSidebarOpen(!isSidebarOpen);
};

const handleNavbarClick = () => {
  setSidebarOpen(!isSidebarOpen);
};
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };  const handleLogout = () => {
    // Implement your logout logic here
};
  return (
    <div className="frame9-home-v2-container">
      <div className="frame9-home-v2-frame9-home-v2">
        <div className="frame9-home-v2-content">          
          <NavbarTop  moduleName="Dashboard" onNavbarClick={toggleSidebar} />
          {/* Moneyflow Graph 
          <div className="frame9-home-v2-moneyflow">
            <span className="frame9-home-v2-text004 HeadingH6">
              <span>Money Flow</span>
            </span>
            <div className="frame9-home-v2-date">
              <span className="frame9-home-v2-text006 bodysmallsemibold">
                <span>Jan 10 - Jan 16</span>
              </span>
              <img
                src={dropdown}
                alt="chevrondown1948"
                className="frame9-home-v2-chevrondown1"
              />
            </div>
            <div className="frame9-home-v2-income">
              <img
                src
                alt="Rectangle11948"
                className="frame9-home-v2-rectangle1"
              />
              <span className="frame9-home-v2-text008 bodysmallsemibold">
                <span>Income</span>
              </span>
            </div>
            <div className="frame9-home-v2-amount">
              <span className="frame9-home-v2-text010 bodysmallregular">
                <span>10k</span>
              </span>
              <span className="frame9-home-v2-text012 bodysmallregular">
                <span>7k</span>
              </span>
              <span className="frame9-home-v2-text014 bodysmallregular">
                <span>5k</span>
              </span>
              <span className="frame9-home-v2-text016 bodysmallregular">
                <span>3k</span>
              </span>
              <span className="frame9-home-v2-text018 bodysmallregular">
                <span>0k</span>
              </span>
            </div>
            <div className="frame9-home-v2-date1">
              <span className="frame9-home-v2-text020 bodysmallregular">
                <span>Jan 10</span>
              </span>
              <span className="frame9-home-v2-text022 bodysmallregular">
                <span>Jan 11</span>
              </span>
              <span className="frame9-home-v2-text024 bodysmallextrabold">
                <span>Jan 12</span>
              </span>
              <span className="frame9-home-v2-text026 bodysmallregular">
                <span>Jan 13</span>
              </span>
              <span className="frame9-home-v2-text028 bodysmallregular">
                <span>Jan 14</span>
              </span>
              <span className="frame9-home-v2-text030 bodysmallregular">
                <span>Jan 15</span>
              </span>
              <span className="frame9-home-v2-text032 bodysmallregular">
                <span>Jan 16</span>
              </span>
            </div>
            <div className="frame9-home-v2-expenses">
              <img
                src
                alt="Rectangle11948"
                className="frame9-home-v2-rectangle11"
              />
              <span className="frame9-home-v2-text034 bodysmallsemibold">
                <span>Expenses</span>
              </span>
            </div>
            <div className="frame9-home-v2-line">
              <img
                src="/line1948-28r.svg"
                alt="line1948"
                className="frame9-home-v2-line01"
              />
              <img
                src="/line1948-1y1p.svg"
                alt="line1948"
                className="frame9-home-v2-line02"
              />
              <img
                src="/line1948-j8eu.svg"
                alt="line1948"
                className="frame9-home-v2-line03"
              />
              <img
                src="/line1948-19h.svg"
                alt="line1948"
                className="frame9-home-v2-line04"
              />
              <img
                src="/line1948-dfi2.svg"
                alt="line1948"
                className="frame9-home-v2-line05"
              />
              <img
                src="/line1948-mae.svg"
                alt="line1948"
                className="frame9-home-v2-line06"
              />
              <img
                src="/line1948-nagh.svg"
                alt="line1948"
                className="frame9-home-v2-line07"
              />
              <img
                src="/line1948-d13s.svg"
                alt="line1948"
                className="frame9-home-v2-line08"
              />
              <img
                src="/line1948-0z.svg"
                alt="line1948"
                className="frame9-home-v2-line09"
              />
              <img
                src="/line1948-yds.svg"
                alt="line1948"
                className="frame9-home-v2-line10"
              />
              <img
                src="/line1948-o3nt.svg"
                alt="line1948"
                className="frame9-home-v2-line11"
              />
              <img
                src="/line1948-5oon.svg"
                alt="line1948"
                className="frame9-home-v2-line12"
              />
              <img
                src="/line1948-4l9g.svg"
                alt="line1948"
                className="frame9-home-v2-line13"
              />
              <img
                src="/line1948-lje9.svg"
                alt="line1948"
                className="frame9-home-v2-line14"
              />
              <img
                src="/line1948-czui.svg"
                alt="line1948"
                className="frame9-home-v2-line15"
              />
              <img
                src="/line1948-fwak.svg"
                alt="line1948"
                className="frame9-home-v2-line16"
              />
              <img
                src="/line1948-erwg.svg"
                alt="line1948"
                className="frame9-home-v2-line17"
              />
              <img
                src="/line1948-swt.svg"
                alt="line1948"
                className="frame9-home-v2-line18"
              />
            </div>
            <div className="frame9-home-v2-chartline">
              <img
                src={blackLine}
                alt="line1948"
                className="frame9-home-v2-line19"
              />
              <img
                src={blueLine}
                alt="line1948"
                className="frame9-home-v2-line20"
              />
              <img src={dot} alt="dot1948" className="frame9-home-v2-dot" />
              <div className="frame9-home-v2-amount1">
                <img
                  src={blackUnion}
                  alt="Union1948"
                  className="frame9-home-v2-union"
                />
                <span className="frame9-home-v2-text036 bodysmallsemibold">
                  <span>$5,052</span>
                </span>
              </div>
            </div>
          </div>yet to implement*/}
          <div className="dashboard-graph">
          <div className="dashboard-minigraph">
            <div className="frame9-home-v2-name ">
               <div className="frame9-home-v2-text038">
                <span className="frame9-home-v2-text039 bodysmallmedium">
                  <span>Total Month Earn</span>
                </span>
                <span className="frame9-home-v2-text041 HeadingH5">
                  <span>$2,456.89</span>
                </span>
              </div> 
              <div className="frame9-home-v2-icon">
                <div className="frame9-home-v2-receipt">
                  <div className="frame9-home-v2-receipt1">
                    <div className="frame9-home-v2-receipt2">
                      <img
                        src="/vectori194-yrm.svg"
                        alt="VectorI194"
                        className="frame9-home-v2-vector02"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-minigraph">
            <div className="frame9-home-v2-name">
              <div className="frame9-home-v2-text043">
                <span className="frame9-home-v2-text044 bodysmallmedium">
                  <span>Total claims</span>
                </span>
                <span className="frame9-home-v2-text046 HeadingH5">
                  <span>$280.00</span>
                </span>
              </div>
            </div>
          </div>
          <div className="dashboard-minigraph">
            <div className="frame9-home-v2-name">
              <div className="frame9-home-v2-text069">
                <span className="frame9-home-v2-text070 bodysmallmedium">
                  <span>Sweep to Bank</span>
                </span>
                <span className="frame9-home-v2-text072 HeadingH5">
                  <span>$1,098.00</span>
                </span>
              </div>
            </div>
            {/* <div className="frame9-home-v2-stats2">
              <img src alt="barI194" className="frame9-home-v2-bar10" />
              <img src alt="barI194" className="frame9-home-v2-bar11" />
              <img src alt="barI194" className="frame9-home-v2-bar12" />
              <img src alt="barI194" className="frame9-home-v2-bar13" />
              <img src alt="barI194" className="frame9-home-v2-bar14" />
              <div className="frame9-home-v2-amount2">
                <img
                  src="/unioni194-axqj.svg"
                  alt="UnionI194"
                  className="frame9-home-v2-union1"
                />
                <span className="frame9-home-v2-text074 bodyxsmallsemibold">
                  <span>$5,052</span>
                </span>
              </div>
            </div> */}
          </div>
          </div>
            <div className="frame9-home-v2-recenttransactions">
            <div className="frame9-home-v2-view">
              <span className="frame9-home-v2-text095 HeadingH6">
                <span>Recent Transactions</span>
              </span>
              <span className="frame9-home-v2-text093 bodysmallsemibold">
                <span>View all</span>
              <img
                src={dropdownside}
                alt="chevrondown1948"
                className="frame9-home-v2-chevrondown3"
              />
              </span>
            </div>
            <div className="frame9-home-v2-list">
              
            <Table columns={columns} data={transactions} />
            </div>
          </div>
          <Wallet />
          <Bank />
          </div>
        <Navbar isOpen={isSidebarOpen}/>
        
        
      </div>
    </div>
  )
}

export default Dashboard
