//import module from "./style.module.css";
import grouptr from "../../assets/GroupToRight.png";
import logo from "../../assets/Logo.png";
import vectorbl from "../../assets/VectorBottomLeft.png";
import middleImg from "../../assets/MiddleImg.png";
import useInput from "../../hooks/use-input";

function Bank() {
  const {
    value: bankAccNumValue,
    valueChangeHandler: bankAccNumChangeHandler,
    reset: bankAccNumReset,
  } = useInput();

  const {
    value: bankRNumValue,
    valueChangeHandler: bankRNumChangeHandler,
    reset: bankRNumReset,
  } = useInput();

  const {
    value: accHolderValue,
    valueChangeHandler: accHolderChangeHandler,
    reset: accHolderReset,
  } = useInput();

  const {
    value: bankNameValue,
    valueChangeHandler: bankNameChangeHandler,
    reset: bankNameReset,
  } = useInput();

  const {
    value: addressLine1,
    valueChangeHandler: addL1ChangeHandler,
    reset: addL1Reset,
  } = useInput();

  const {
    value: addressLine2Value,
    valueChangeHandler: addL2ChangeHandler,
    reset: addL2Reset,
  } = useInput();

  const {
    value: cityValue,
    valueChangeHandler: cityChangeHandler,
    reset: cityReset,
  } = useInput();

  const {
    value: stateValue,
    valueChangeHandler: stateChangeHandler,
    reset: stateReset,
  } = useInput();

  const {
    value: countryValue,
    valueChangeHandler: countryChangeHandler,
    reset: countryReset,
  } = useInput();

  const {
    value: pcValue,
    valueChangeHandler: pcChangeHandler,
    reset: pcReset,
  } = useInput();
  const {
    value: swiftCodeValue,
    valueChangeHandler: swiftCodeChangeHandler,
    reset: swiftCodeReset,
  } = useInput();

  const {
    value: iBanValue,
    valueChangeHandler: iBanChangeHandler,
    reset: iBanReset,
  } = useInput();

  const {
    value: accTypeValue,
    valueChangeHandler: accTypeChangeHandler,
    reset: accTypeReset,
  } = useInput();

  function onSubmitHandler(event) {
    event.preventDefault();
    bankAccNumReset();
    bankRNumReset();
    pcReset();
    countryReset();
    stateReset();
    cityReset();
    accHolderReset();
    bankNameReset();
    addL1Reset();
    addL2Reset();
    swiftCodeReset();
    iBanReset();
    accTypeReset();
  }
  return (
    <div className={module["parentContainer"]}>
      <div className={module["container"]}>
        <div className={module["innerContainer"]}>
          <div className={module["firstParent"]}>
            <div className={module["innerFirstParent"]}>
              <div className={module["subInnerParent"]}>
                <div className={module["logo"]}>
                  <img src={logo} alt="SkenderPayLogo" />
                </div>
                <div className={module["textSpan"]}>Skenderpay</div>
              </div>
              <div className={module["firstImage"]}>
                <img src={grouptr} alt="Vector Top Right" />
              </div>
            </div>
            <div className={module["middleImage"]}>
              <img src={middleImg} alt="Middle showcase" />
            </div>

            <div className={module["textParent"]}>
              <span className={module["text1"]}>
                {" "}
                Speady, Easy and Fast <br />{" "}
              </span>
              <span className={module["text2"]}>
                Overpay help you set saving goals, earn cash back offers, Go to
                disclaimer <br />
                for more details and get paychecks up to two days early. Get a
                <span className={module["dollarText"]}>$20</span>
                bonus <br />
                when you receive qualifying direct deposits
              </span>
            </div>
            <div className={module["secondImage"]}>
              <img src={vectorbl} alt="Vector Bottom Left" />
            </div>
          </div>
          <div className={module["secondParent"]}>
            <div className={module["formParent"]}>
              <div className={module["formHeading"]}>
                <h2>Complete Your Bank Information</h2>
              </div>
              <form onSubmit={onSubmitHandler}>
                <div>
                  <label
                    className={module["labelBankAccountNumber"]}
                    htmlFor="bankAccNum"
                  >
                    Bank Account Number*
                  </label>
                  <input
                    type="text"
                    id="bankAccNum"
                    className={module["inputBankAccountNumber"]}
                    required
                    value={bankAccNumValue}
                    onChange={bankAccNumChangeHandler}
                  />
                </div>
                <div>
                  <label
                    className={module["labeRoutingNumber"]}
                    htmlFor="routingNumber"
                  >
                    Routing Number *
                  </label>
                  <input
                    type="text"
                    id="routingNumber"
                    className={module["inputRoutingNumber"]}
                    value={bankRNumValue}
                    onChange={bankRNumChangeHandler}
                    required
                  />
                </div>
                <div>
                  <label className={module["labelAccHName"]} htmlFor="accHName">
                    Account Holder Name *
                  </label>
                  <input
                    type="text"
                    id="accHName"
                    className={module["inputAccHName"]}
                    required
                    value={accHolderValue}
                    onChange={accHolderChangeHandler}
                  />
                </div>
                <div>
                  <label htmlFor="bankName" className={module["labelbankName"]}>
                    Bank Name *
                  </label>
                  <input
                    id="bankName"
                    type="text"
                    className={module["inputbankName"]}
                    required
                    value={bankNameValue}
                    onChange={bankNameChangeHandler}
                  />
                </div>

                <div>
                  <label
                    className={module["labelAddressLine1"]}
                    htmlFor="addressLine1"
                  >
                    Address(Line 1)
                  </label>
                  <input
                    type="text"
                    id="addressLine1"
                    className={module["inputaddressLine1"]}
                    onChange={addL1ChangeHandler}
                    value={addressLine1}
                  />
                </div>

                <div>
                  <label
                    className={module["labelAddressLine2"]}
                    htmlFor="addressLine2"
                  >
                    Address (Line 2)
                  </label>
                  <input
                    type="text"
                    id="addressLine2"
                    className={module["inputAddressLine2"]}
                    onChange={addL2ChangeHandler}
                    value={addressLine2Value}
                  />
                </div>

                <div>
                  <label className={module["labelCity"]} htmlFor="city">
                    City
                  </label>
                  <input
                    type="text"
                    id="city"
                    className={module["inputCity"]}
                    onChange={cityChangeHandler}
                    value={cityValue}
                  />
                </div>

                <div>
                  <label htmlFor="state" className={module["labelState"]}>
                    State
                  </label>
                  <input
                    id="state"
                    type="text"
                    className={module["inputState"]}
                    onChange={stateChangeHandler}
                    value={stateValue}
                  />
                </div>

                <div>
                  <label
                    htmlFor="postalCode"
                    className={module["labelPostalCode"]}
                  >
                    Postal Code
                  </label>
                  <input
                    id="postalCode"
                    type="text"
                    className={module["inputPostalCode"]}
                    onChange={pcChangeHandler}
                    value={pcValue}
                  />
                </div>

                <div>
                  <label htmlFor="country" className={module["labelCountry"]}>
                    Country
                  </label>
                  <input
                    id="country"
                    type="text"
                    className={module["inputCountry"]}
                    onChange={countryChangeHandler}
                    value={countryValue}
                  />
                </div>

                <div>
                  <label
                    htmlFor="swiftCode"
                    className={module["labelSwiftCode"]}
                  >
                    Swift Code
                  </label>
                  <input
                    id="swiftCode"
                    type="text"
                    className={module["inputSwiftCode"]}
                    onChange={swiftCodeChangeHandler}
                    value={swiftCodeValue}
                  />
                </div>

                <div>
                  <label htmlFor="iBan" className={module["labelIBan"]}>
                    IBan
                  </label>
                  <input
                    id="iBan"
                    type="text"
                    className={module["inputIBan"]}
                    onChange={iBanChangeHandler}
                    value={iBanValue}
                  />
                </div>

                <div>
                  <label htmlFor="accType" className={module["labelaccType"]}>
                    Account Type
                  </label>
                  <input
                    id="accType"
                    type="text"
                    className={module["inputaccType"]}
                    onChange={accTypeChangeHandler}
                    value={accTypeValue}
                  />
                </div>

                <div>
                  <button type="submit" className={module["loginButton"]}>
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Bank;
