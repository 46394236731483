import sideImg1 from "../../assets/SideImage1.png";
import sideframe1 from "../../assets/SideFrame1.png";
import sideframe2 from "../../assets/SideFrame2.png";
import grouptr from "../../assets/GroupToRight.png";
import logo from "../../assets/Logo.png";
import PropTypes from 'prop-types';
import vectorbl from "../../assets/VectorBottomLeft.png";
import React, { useState, useEffect } from 'react';
const LeftPanel = ({ panelType }) => {
  const getContent = () => { 
    switch (panelType) {
      case 'desc1':
        return (
        <div className="frame4-signup-filled-title">
        <div className="frame4-signup-filled-text18">
          <span className="frame4-signup-filled-text19 HeadingH2">
            <span>Fast, Secure, User-Friendly Digital Payments! </span>
          </span>
          <span className="frame4-signup-filled-text21">
            <span className="frame4-signup-filled-text22">
            Exciting developments are on the horizon for the digital payment landscape with the imminent
                arrival of a cutting-edge platform at the intersection of AI and blockchain payments technology. <br />
                <span className="dollarText"></span>
                Our innovative solution promises to revolutionize transactions offering unparalleled
                security, efficiency, and transparency.  <br />
                Stay tuned for the unveiling of SkenderPay platform the game-changing digital payment platform,
                set to redefine the way we engage in financial transactions.
              </span>
          </span>
        </div>
      </div>);
      default:
        return (<div className="frame4-signup-filled-title">
        <div className="frame4-signup-filled-text18">
          <span className="frame4-signup-filled-text19 HeadingH2">
            <span>Speady, Easy and Fast</span>
          </span>
          <span className="frame4-signup-filled-text21">
            <span className="frame4-signup-filled-text22">
              Overpay help you set saving goals, earn cash back offers, Go
              to disclaimer for more details and get paychecks up to two
              days early. Get a
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className="frame3-signup-filled-text23">$20</span>
            <span> bonus when you receive qualifying direct deposits</span>
          </span>
        </div>
      </div>);
    }
  }
  return (
        <div className="frame4-signup-filled-illustration">
          <div className="frame4-signup-filled-pattern">
            <div className="frame4-signup-filled-group1">
              <img src={grouptr} alt="Vector Top Right" />
            </div>
            <img
              src={vectorbl}
              alt="Vector Bottom Left"
              className="frame4-signup-filled-vector36"
            />
          </div>
          <div className="frame4-signup-filled-logo">
            <div className="frame4-signup-filled-frame48">
              <div className="frame4-signup-filled-logo1">
                <div className="frame4-signup-filled-logo2">
                  <div className="frame4-signup-filled-ontologyont">
                    <img
                      src={logo}
                      alt="SkenderPay1I114"
                      className="frame4-signup-filled-skender-pay1"
                    />
                  </div>
                </div>
              </div>
              <span className="frame4-signup-filled-text16 HeadingH5">
                <span>Skenderpay</span>
              </span>
            </div>
          </div>
          {getContent()}
          <div className="frame4-signup-filled-group254">
            <div className="frame4-signup-filled-group253">
              <img src={sideImg1} alt="sideImage1" />
            </div>
            <img
              src={sideframe1}
              alt="Frame51111143"
              className="frame4-signup-filled-frame5111"
            />
            <img
              src={sideframe2}
              alt="Frame5511143"
              className="frame4-signup-filled-frame551"
            />
          </div>
        </div>
  );
};
LeftPanel.propTypes = {
  panelType: PropTypes.oneOf(['leftPanel1', 'leftPanel2']).isRequired,
};
export default LeftPanel;
