import React from "react";
import emptyWallet from "../../assets/empty-wallet-change-icon.png";
import moneySend from "../../assets/money-send-icon.png";
import sideImg1 from "../../assets/SideImage1.png";
import sideframe1 from '../../assets/SideFrame1.png'
import sideframe2 from '../../assets/SideFrame2.png'
import grouptr from '../../assets/GroupToRight.png'
import logo from '../../assets/Logo.png'
import LeftPanel  from "../Index/LeftPanel";
import vectorbl from '../../assets/VectorBottomLeft.png'

import "./HomeStyle.css";
import { useNavigate } from "react-router-dom";

const Home = (props) => {
  const navigate = useNavigate();

  function onClickHandler () {
    navigate("/signup2")
  }

  return (
    <div className="frame4-signup-filled-container">
      <div className="frame4-signup-filled-frame4-signup-filled">
        <div className="frame4-signup-filled-content">
          <div className="frame4-signup-filled-text">
            <span className="frame4-signup-filled-text01 bodylargesemibold">
              <span>Privacy Policy</span>
            </span>
            <span className="frame4-signup-filled-text03 bodylargesemibold">
              <span>Copyright 2022</span>
            </span>
          </div>
          <div className="frame4-signup-filled-group18311">
            <div className="frame4-signup-filled-text05">
              <span className="frame4-signup-filled-text06 HeadingH3">
                <span>Tell us your main reason for using Skender?</span>
              </span>
              <span className="frame4-signup-filled-text08 bodylargemedium">
                <span>
                  Tell us about the current situation and we will make the right
                  recommendations for you
                </span>
              </span>
            </div>
            <div className="frame4-signup-filled-reasoncard">
              <div className="frame4-signup-filled-reason">
              <button className="frame4-signup-filled-reason"></button>
                <span className="frame4-signup-filled-text10 bodylargesemibold">
                  <span>I want to create a wallet</span>
                </span>
                <div className="frame4-signup-filled-icon">
                  <div className="frame4-signup-filled-emptywalletchange">
                    <div className="frame4-signup-filled-emptywalletchange1">
                      <div className="frame4-signup-filled-emptywalletchange2">
                        <img
                          src={emptyWallet}
                          alt="VectorI114"
                          className="frame4-signup-filled-vector"
                        />
                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame4-signup-filled-reason">
                <button className="frame4-signup-filled-reason">
                <span className="frame4-signup-filled-text12 bodylargesemibold">
                  <span>I want a payment gateway</span>
                </span>
                </button>
                <div className="frame4-signup-filled-icon1">
                  <div className="frame4-signup-filled-moneysend">
                    <div className="frame4-signup-filled-moneysend1">
                      <div className="frame4-signup-filled-moneysend2">
                        <img
                          src={moneySend}
                          alt="VectorI114"
                          className="frame4-signup-filled-vector05"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame4-signup-filled-reasoncard1">
              <div className="frame4-signup-filled-reason">
              <button className="frame4-signup-filled-reason"></button>
                <span className="frame4-signup-filled-text14 bodylargesemibold">
                  <span>I want to import an Skender Wallet</span>
                </span>
                <div className="frame4-signup-filled-icon2">
                  <div className="frame4-signup-filled-emptywalletchange3">
                    <div className="frame4-signup-filled-emptywalletchange4">
                      <div className="frame4-signup-filled-emptywalletchange5">
                        <img
                          src={emptyWallet}
                          alt="VectorI114"
                          className="frame4-signup-filled-vector10"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <button onClick={onClickHandler} className="frame4-signup-filled-button">
            <span className="frame4-signup-filled-text65 bodylargeextrabold">
              <span>Continue</span>
            </span>
          </button>
          </div>
        </div>
        
        <LeftPanel  />
      </div>
    </div>
  );
};

export default Home;
