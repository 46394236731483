import React, { useState } from 'react';
import './messages.css';

const Conversation = ({ user, selectedUser, messages }) => {
  const [newMessage, setNewMessage] = useState('');

  const filteredMessages = messages.filter(
    (message) =>
      (message.sender === user && message.receiver === selectedUser) ||
      (message.sender === selectedUser && message.receiver === user)
  );

  const handleSendMessage = () => {
    if (newMessage.trim() === '') return;
    const newMessageObj = {
      id: messages.length + 1,
      sender: user,
      receiver: selectedUser,
      message: newMessage,
      timestamp: new Date().toISOString(),
    };
    setNewMessage('');
    // Add the new message to the existing messages
    // This depends on how you manage your messages state
    // You may need to use a callback function to update the state properly
  };

  return (
    <div className="conversation">
      <div className='conversation-header'>
        <h2>{selectedUser}</h2>
      </div>
      <div className="message-container">
        {filteredMessages.map((message) => (
          <div
            key={message.id}
            className={`message-bubble ${message.sender === user ? 'current-user' : 'selected-user'}`}
          >
            <p className="message-text">{message.message}</p>
            <p className="message-timestamp">{message.timestamp}</p>
          </div>
        ))}
        <div className="compose-message">
          <input
            type="text"
            placeholder="Type a message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSendMessage();
              }
            }}
          />
          <button onClick={handleSendMessage}>Send</button>
        </div>
      </div>
    </div>
  );
};

export default Conversation;
