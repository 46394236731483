import React from 'react';

import sideImg1 from "../../assets/SideImage1.png";
import sideframe1 from "../../assets/SideFrame1.png";
import sideframe2 from "../../assets/SideFrame2.png";
import grouptr from "../../assets/GroupToRight.png";
import logo from "../../assets/Logo.png";
import vectorbl from "../../assets/VectorBottomLeft.png";
import useInputVal from "../../hooks/use-input-val";
import "./style2.css";
import { useNavigate } from "react-router-dom";

const Signup = (props) => {
  const validateEmail = (input) => {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (input.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  };

  const validateName = (input) => {
    var validRegex = /^[a-zA-Z]/;
    if (input.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  };

  const validatePassword = (input) => {
    let out = false;
    var lowerCaseLetters = /[a-z]/g;
    if (input.match(lowerCaseLetters)) {
      out = true;
    } else {
      out = false;
    }

    // Validate capital letters
    var upperCaseLetters = /[A-Z]/g;
    if (input.match(upperCaseLetters)) {
      out = true;
    } else {
      out = false;
    }

    // Validate numbers
    var numbers = /[0-9]/g;
    if (input.match(numbers)) {
      out = true;
    } else {
      out = false;
    }

    // Validate length
    if (input.length >= 8) {
      out = true;
    } else {
      out = false;
    }
    return out;
  };

  const {
    value: enteredFName,
    isValid: enteredFNameIsValid,
    hasError: fNameInputHasError,
    valueChangeHandler: fNameInputChangeHandler,
    blurHandler: fNameInputBlurHandler,
    reset: fNameInputReset,
  } = useInputVal(validateName);

  const {
    value: enteredLName,
    isValid: enteredLNameIsValid,
    hasError: lNameInputHasError,
    valueChangeHandler: lNameInputChangeHandler,
    blurHandler: lNameInputBlurHandler,
    reset: lNameInputReset,
  } = useInputVal(validateName);

  const {
    value: enteredEmail,
    isValid: enteredEmailIsValid,
    hasError: emailInputHasError,
    valueChangeHandler: emailInputChangeHandler,
    blurHandler: emailInputBlurHandler,
    reset: emailInputReset,
  } = useInputVal(validateEmail);


  const {
    value: enteredPassword,
    isValid: enteredPasswordIsValid,
    hasError: passwordHasError,
    valueChangeHandler: passwordChangeHandler,
    blurHandler: passwordBlurHandler,
    reset: passwordInputReset,
  } = useInputVal(validatePassword);

  const {
    value: enteredConfPassword,
    isValid: enteredConfPasswordIsValid,
    hasError: confPasswordHasError,
    valueChangeHandler: confPasswordChangeHandler,
    blurHandler: confPasswordBlurHandler,
    reset: confPasswordInputReset,
  } = useInputVal(validatePassword);

  let formIsValid = false;

  let passwordnotMatch = true;
  if (enteredPassword === enteredConfPassword) {
    passwordnotMatch = false;
  }

  if (
    enteredEmailIsValid &&
    enteredPasswordIsValid &&
    enteredConfPasswordIsValid &&
    !passwordnotMatch &&
    enteredFNameIsValid &&
    enteredLNameIsValid
  ) {
    formIsValid = true;
  }

  const navigate = useNavigate();

  if (formIsValid) {
    
  }
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    if (!formIsValid) {
      return;
    }
    const data = {
      firstName: enteredFName,
      lastName: enteredLName,
      email: enteredEmail,
      password: enteredPassword,
    };
    const ret = await pushToDB(data);
    console.log(ret);
    if(ret === "ok"){
      emailInputReset();
      passwordInputReset();
      confPasswordInputReset();
      fNameInputReset();
      lNameInputReset();
      navigate("/profile");
    }else{
      console.log(ret)
    }
  };

  const pushToDB = async (data) => {
    try {
      const resp = await fetch("https://skenderpay.xyz:8443/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const parsedResp = await resp.json();
      console.log(parsedResp);
      if (parsedResp.message === 'success'){
        return "ok";
      }else{
        return resp;
      }
    } catch (e) {
      return e;
    }
  };

  const emailClasses = emailInputHasError
    ? "input-username invalid"
    : "input-username";
  const passwordClasses = passwordHasError
    ? "input-password invalid"
    : "input-password";
  const confPasswordClasses = confPasswordHasError
    ? "input-conf-password invalid"
    : "input-conf-password";
  const fnameClasses = fNameInputHasError
    ? "input-fname invalid"
    : "input-fname";
  const lnameClasses = lNameInputHasError
    ? "input-lname invalid"
    : "input-lname";

  return (
    <div className="frame3-signup-empty-container">
      <div className="frame3-signup-empty-frame3-signup-empty">
        <div className="frame3-signup-empty-content">
          <div className="frame3-signup-empty-text">
            <span className="frame3-signup-empty-text01 HeadingH3">
              <span>
                Create a Password
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <span className="frame3-signup-empty-text03 bodylargeregular">
              <span>
                Safeguarding Your Digital Wealth with an Extra Layer of
                Protection
              </span>
            </span>
          </div>
          <button
            disabled={!formIsValid}
            onClick={onSubmitHandler}
            className="frame3-signup-empty-button"
          >
            <span className="frame3-signup-empty-text05 bodylargeextrabold">
              <span>Continue</span>
            </span>
          </button>
          <div className="frame3-signup-empty-or"></div>
          <div className="frame3-signup-empty-text07">
            <span className="frame3-signup-empty-text08 bodylargesemibold">
              <span>Privacy Policy</span>
            </span>
            <span className="frame3-signup-empty-text10 bodylargesemibold">
              <span>Copyright 2023</span>
            </span>
          </div>
          <div>
            <label htmlFor="firstName" className="label-fname">
              First Name
            </label>
            <input
              id="firstName"
              type="text"
              className={fnameClasses}
              onChange={fNameInputChangeHandler}
              placeholder="Enter your first name"
              onBlur={fNameInputBlurHandler}
              value={enteredFName}
              required
            />
          </div>

          <div>
            <label htmlFor="lastName" className="label-lname">
              Last Name
            </label>
            <input
              id="lastName"
              type="text"
              className={lnameClasses}
              onChange={lNameInputChangeHandler}
              placeholder="Enter your last name"
              onBlur={lNameInputBlurHandler}
              value={enteredLName}
            />
          </div>

          <div>
            <label htmlFor="email" className="label-username">
              Email
            </label>
            <input
              id="email"
              type="text"
              className={emailClasses}
              onChange={emailInputChangeHandler}
              placeholder="Enter your email address"
              onBlur={emailInputBlurHandler}
              value={enteredEmail}
            />
          </div>
          <div>
            <label htmlFor="password" className="label-password">
              Password
            </label>
            <input
              type="password"
              id="password"
              className={passwordClasses}
              onChange={passwordChangeHandler}
              placeholder="Enter a password"
              onBlur={passwordBlurHandler}
              value={enteredPassword}
            />
          </div>
          <div>
            <label htmlFor="confPassword" className="label-conf-password">
              Confirm Password
            </label>
            <input
              id="confPassword"
              type="password"
              className={confPasswordClasses}
              onChange={confPasswordChangeHandler}
              placeholder="Retype your password"
              onBlur={confPasswordBlurHandler}
              value={enteredConfPassword}
            />
          </div>
        </div>
        <div className="frame4-signup-filled-illustration">
          <div className="frame4-signup-filled-pattern">
            <div className="frame4-signup-filled-group1">
              <img src={grouptr} alt="Vector Top Right" />
            </div>
            <img
              src={vectorbl}
              alt="Vector Bottom Left"
              className="frame4-signup-filled-vector36"
            />
          </div>
          <div className="frame4-signup-filled-logo">
            <div className="frame4-signup-filled-frame48">
              <div className="frame4-signup-filled-logo1">
                <div className="frame4-signup-filled-logo2">
                  <div className="frame4-signup-filled-ontologyont">
                    <img
                      src={logo}
                      alt="SkenderPay1I114"
                      className="frame4-signup-filled-skender-pay1"
                    />
                  </div>
                </div>
              </div>
              <span className="frame4-signup-filled-text16 HeadingH5">
                <span>Skenderpay</span>
              </span>
            </div>
          </div>
          <div className="frame4-signup-filled-title">
            <div className="frame4-signup-filled-text18">
              <span className="frame4-signup-filled-text19 HeadingH2">
                <span>Speady, Easy and Fast</span>
              </span>
              <span className="frame4-signup-filled-text21">
                <span className="frame4-signup-filled-text22">
                  Overpay help you set saving goals, earn cash back offers, Go
                  to disclaimer for more details and get paychecks up to two
                  days early. Get a
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <span className="frame3-signup-filled-text23">$20</span>
                <span> bonus when you receive qualifying direct deposits</span>
              </span>
            </div>
          </div>
          <div className="frame4-signup-filled-group254">
            <div className="frame4-signup-filled-group253">
              <img src={sideImg1} alt="sideImage1" />
            </div>
            <img
              src={sideframe1}
              alt="Frame51111143"
              className="frame4-signup-filled-frame5111"
            />
            <img
              src={sideframe2}
              alt="Frame5511143"
              className="frame4-signup-filled-frame551"
            />
          </div>
        </div>
        <div className="frame3-signup-empty-group18315">
          <span className="frame3-signup-empty-text61 bodymediumregular">
            <span>Password</span>
          </span>
          <span className="frame3-signup-empty-text63 bodymediumregular">
            <span>Seed Phrase</span>
          </span>
          <span className="frame3-signup-empty-text65 bodymediumregular">
            <span>Confirm</span>
          </span>
          <div className="frame3-signup-empty-group18314">
            <span className="frame3-signup-empty-text67 bodyxlargesemibold">
              1
            </span>
          </div>
          <div className="frame3-signup-empty-group18312">
            <span className="frame3-signup-empty-text68 bodyxlargesemibold">
              2
            </span>
          </div>
          <div className="frame3-signup-empty-group18313">
            <span className="frame3-signup-empty-text69 bodyxlargesemibold">
              3
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;