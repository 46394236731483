import React, { useState, useEffect } from 'react';
import axios from 'axios';
import quickt from '../../assets/quicktransfer.png';

const Dashboard = (props) => {
  const [bankArray, setBankArray] = useState([]);

  useEffect(() => {
    const fetchBankDetails = async () => {
      try {
        const resp = await axios.post("https://skenderpay.xyz:8443/getBankDetails", { userId: 5 });
        setBankArray(resp.data.data);
      } catch (error) {
        console.error('Error fetching bank details:', error);
      }
    };

    fetchBankDetails();
  }, []);

  return (
    <div className="frame9-home-v2-quicktransfer">
      <img src={quickt} alt="backgroundI194" className="frame9-home-v2-background2" />
      <span className="frame9-home-v2-text121 HeadingH6">
        <span>Sweep to Bank</span>
      </span>
      <div className="frame9-home-v2-input">
        <div className="frame9-home-v2-selectcard">
          {bankArray.length > 0 && (
            <div className="frame9-home-v2-card3">
              <img
                src="/tracedimagei194-c9a.svg"
                alt="TracedImageI194"
                className="frame9-home-v2-traced-image"
              />
              <span className="frame9-home-v2-text123 bodymediummedium">
                <span>{bankArray[0].bank_name}</span>
              </span>
            </div>
          )}
          <div className="frame9-home-v2-amount3">
            <span className="frame9-home-v2-text125 bodymediumextrabold">
              <span>$10,431</span>
            </span>
            <img
              src="/chevrondowni194-ilxn.svg"
              alt="chevrondownI194"
              className="frame9-home-v2-chevrondown4"
            />
          </div>
        </div>
        <div className="frame9-home-v2-inputamount">
          <span className="frame9-home-v2-text127 bodysmallmedium">
            <span>Enter amount</span>
          </span>
          <div className="frame9-home-v2-input1">
            <div className="frame9-home-v2-frame8">
              <input type="text" className="frame9-home-v2-text129 HeadingH4"></input>
              <img
                src="/line1i194-opsy.svg"
                alt="Line1I194"
                className="frame9-home-v2-line1"
              />
            </div>
          </div>
        </div>
      </div>
      <button className="frame9-home-v2-button">
        <span className="frame9-home-v2-text131 bodylargeextrabold">
          <span>Send Money</span>
        </span>
      </button>
    </div>
  );
};

export default Dashboard;
