import { createContext } from "react";
import { useState } from "react";

const UserProgressContext = createContext({
    progress: '',
    showSend: () => {},
    hideSend: () => {}
})


export function UserProgressContextProvider({children}){
    const [userProgress, setUserProgress] = useState('');

    function showSend() {
        setUserProgress('sendMoney');
    }

    function hideSend() {
        setUserProgress('');
    }

    const userProgressCtx = {
        progress: userProgress,
        showSend,
        hideSend
    };

    return(
        <UserProgressContext.Provider value={userProgressCtx}>{children}</UserProgressContext.Provider>
    )
}

export default UserProgressContext